@font-face {
  font-family: 'silkaregular';
  src: url('../../assets/Roboto-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;

}
.medicos {
    grid-area: main;
    font-family: 'silkaregular';
  
  }