.sidebar {
    grid-area: sidebar;
    background: #f7faff;
    overflow-y: auto;
    padding: 0px;
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
    align-items: center;
    border-radius: 5px;
    height: 370px;
    border: 1px solid lightgray;
    width: 228px;
    position: fixed;
    left: 0px;
    top: 74px;
}

.sidebar__icon {
    grid-area: icone;
    height: 9.8vh;
    display: flex;
    justify-content: center;
    border-radius: 5px;
    /* background-image: linear-gradient(to bottom, #fff,#cecece 100%);  */
    background-color: black;
    position: fixed;
    left: 100px;
} 

.logo {
    align-self:  center;
    position: fixed;
    height: 50px;

}

.sidebar__nav{
    display: flex;
    flex-direction: column;

}

.sidebar__nav a {
    padding: 35px 0px;
    text-decoration: none;
    font-family: 'Montserrat', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    color: #7a97c1;
    text-align: center;
    box-shadow: #55e3d3; 
    /* border: 1px solid rgba(75, 71, 116, 0.05); */


}

.sidebar__nav a:hover{
    background-color:#dfeafc;
    border-radius: 5px;
    height: 100%;
  }

.sidebar__nav a:active{
    color: #55e3d3;
    background-color: #fff;
  }


