.formpage {
  display: flex;
  grid-area: main;
  height: 90vh;
  width: 100%;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
background-color: rgb(233, 241, 245);

}


.container-form {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 650px;
  width: 850px;
  padding: 10px;

}
h1{
  height: 30px;
  font-size: 1.5rem;
  text-align: center;
  padding:20px;
  position: relative;
  font-family: 'Poppins',sans-serif;
  border-bottom: 3px solid;
  border-color:#71b7e6;
}




label {
  font-size: 15px;
  font-weight: 500;
  position: relative;
  font-family: 'Poppins',sans-serif;
}


.former {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 600px;
  width: 900px;
  border-radius: 10px;
  box-shadow: 0 5px 10px rgba(0,0,0,0.15);
  background-color: #fff;
  justify-content: space-between;
  padding: 30px;
}
.form-group__radio {
  display: flex;
  padding: 5px;
}
.form-group__radio label{
  padding: 10px;
}
.option{
  width: 140px;
  padding-left: 10px;
}



.form-group__fields {
  display: flex;
  flex-direction: column;
  height: 120px;
  width: 800px;
  flex-wrap: wrap;
  padding: 10px;
  gap: 5px 5px;
}

.former input{
  color:black;
  background-color: #eeeff8;
  height: 25px;
  border-radius: 5px;
  border: none;
  width: 150px;
  outline: none;
  padding: 3px;
  box-shadow: 2px 2px 1px rgba(0,0,0,0.15);
}
.former input:hover{
  background-color: #dfe2f7;
}
.former .radio{
  width: 10px;
  height: 10px;
  align-self: center;
}
.select {
  background-color: darkgreen;
  height: 35px;
  width: 250px;
  background-color: #eeeff8;
  border-radius: 5px;
  border: none;
  margin: 0 6px;
}
.Enviar{
  background-color: rgb(65, 121, 241);
  color: #f7faff;
  font-family: 'Montserrat';
  border: none;
  border-radius: 50px;
  cursor: pointer;
  transition: all 0.3s ease 0s;
  font-size: 14px;
  width: 150px;
  height: 35px;
  margin-top: 10px;
}

.Enviar:hover {
  background-color: rgb(17, 240, 110);
}

.areadetexto {
  color:black;
  background-color: #eeeff8;
  border-radius: 5px;
  border: none;
  outline: none;
  padding: 3px;
  box-shadow: 2px 2px 1px rgba(0,0,0,0.15);
  resize: none;
}
