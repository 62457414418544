.dashboard {
    grid-area: main;
    display: flex;
    height: 90vh;
    align-items: center;
    justify-content: center;
    font-size: 3rem;
    background-color: azure;
  
  
  }