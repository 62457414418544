
/* INSERÇÃO DA FONTE MONTSERRAT BOLD*/
@font-face {
  font-family: 'roboto';
  src: url('./assets/Roboto-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;

}
@font-face{
  font-family: Montserrat;
  src: url('./assets/Montserrat-Bold.ttf');
}

/* RESET DA PAGINA */
* {
  margin: 0;
  padding: 0;
}

body {
  box-sizing: border-box;
  font-family: 'roboto', sans-serif;
  background-color: #ededed;
}

/* CRIAÇÃO DO LAYOUT USANDO GRIDLAYOUT
LAYOUT USANDO 100% DO CAMPO DE VISÃO DA TELA (VH) FRACIONADO EM 3 COLUNAS E 2 LINHAS. */
.container {
  display: grid;
  height: 100vh;
  grid-template-columns: 15vw 85vw;
  grid-template-rows: 10vh 85fr;
  grid-template-areas:
    "icone nav"
    "sidebar main";
}

