.navbar {
    background: #f7faff;
    grid-area: nav;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 30px 0 30px;
    border-bottom: 1px solid lightgray;
    background-color: #f7faff;
    border-radius: 5px;
    height: 9.8vh;
    font-size: 20px;
    position: fixed;
    left: 230px;
    top: 0;
    width: 80%;
    z-index:100;

  }

  .navbar a {
    text-decoration: none;
    padding: 5px;
  }


  .nav__right {
    width: 85vw;
    display: flex;
    align-content: center;
    

  }

  .nav__right--menu-botao{
    padding-right: 2em;
    width: 1em;
    display: none;

  }

  .nav__right--titulo {
    font-family: 'Montserrat', 'Lucida Sans Unicode';
    font-size: "0px";
    color: #7a97c1;
    align-self: center;
  }
  .titulodapagina {
    font-family: 'Montserrat', 'Lucida Sans Unicode';
    font-size: "600px";
    color: #7a97c1;
    align-self: center;
  }

  .nav__center{
    width:30em;
    display: flex;
    justify-content: space-around;

  }
  



  .nav__left {
    border-left: 1px solid #ccc;
    width: 300px;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    font-size: 12px;
  }
  
  .nav__left--avatar{
    width: 30px;
   }
 
  .nav__left--username{
    font-family: 'Montserrat', 'Lucida Sans Unicode';
    font-size: "200px";
    color: #7a97c1;
    display: flex;
    align-self: center;
    font-size: 14px;
  }
  



