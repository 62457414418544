 .hospitais {
      grid-area: main;
      transition: 'all 0.3s ease 0s';  
      font-family: 'Poppins',sans-serif;
    
    }

    .contatoscontainer{
      display: flex;
      padding: 10px;
      color:black;
      transition: 'all 0.3s ease 0s'; 
      height: 100px;
      flex-flow: row wrap;
    
    }
    .table {
      background-color: #e6edf8;
      border-radius: 20px;
      width: 300px;
      padding: 10px;
    }

    