.contratos {
    grid-area: main;
    background-color: rgb(233, 241, 245);
    display: flex;
    height: 90vh;
    align-items: center;
    justify-content: center;
    font-size: 3rem;
    flex-direction: column;
    font-family: 'Montserrat', 'Lucida Sans Unicode';
    font-weight: 500;
    
  
  }
.titulo{
  font-size: 0.3em;
}
.boxarea {
  height: 40vh;
  width: 71vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  color: #7a97c1;
}
.tipocontrato{
  font-size: 0.2em;
  align-self: flex-start;
  padding-top: 20px;
  padding-left: 10px;
}
.box 
{
  display: grid;
  grid-template-columns: 10vw, 10vw, 50vw;
  grid-template-rows: 1fr;
  grid-template-areas: "area1 area2 area3";
  background-color: #fff;
  height: 30vh;
  width: 70vw;
  padding: 3px;
  box-shadow:4px 2px 2px #ccc; 
  border: 1px solid darkgray;
}
.controle{
  font-size: 0.2em;
  align-self: flex-end;
  padding-top: 5px;
  padding-right: 75px;
  width: 60px;
  display: flex;
  gap: 10px;
}
.controle a {

  padding: 10px;
  background-color: rgba(0,82, 155, 0.8);
  border-radius:  3px;
  width: 50px;
  text-decoration: none;
  opacity: 0.8;
  color: #fff;
  box-shadow: 2px 2px 1px rgba(0,0,0,0.15);
 
}
.controle a:hover {
  background-color: rgb(0, 158, 21);
}

.area1{
  border-right: 1px solid #cecece;
  width: 10vw;
  writing-mode: vertical-rl;
  text-orientation: mixed;
  text-align: center;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}
.area2{
  width: 10vw;
  border-right: 1px solid #cecece;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-bottom: 20px;
  padding-left: 10px;
}
.area3{
  width: 50vw;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  max-height: 30vh;
  max-width: 50vw;
  font-size: 0.3em;
}

.item {
  padding: 10px;
}

.nav__center--button{
  display: flex;
  background-color: #fff;
text-decoration: none;
  color: black;
  border: 3px solid rgb(0,82, 155, 0.8);
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.3s ease 0s;
  font-size: 13px;
  justify-content: center;
  padding-top: 12px;
  height: 30px;
  width: 160px;
 position: relative;
 left: 500px;
 box-shadow: 2px 2px 1px rgba(0,0,0,0.15);
 color: #7a97c1;
 transition: all 0.2s;

}
.nav__center--button:hover{
  background-color: rgb(0,82, 155, 0.8);
  color: #fff;
  transition: all 0.2s;
}

