
.containeragenda {
  display: flex;
  grid-area: main;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 2.5rem;
  font-family: 'Montserrat', 'Lucida Sans Unicode';
  font-weight: 500;



}

.areaagenda1{

  height: 320px;
  width: 90%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  border: 2px solid black;
  padding: 0px;
  margin: 0px;
  box-sizing: border-box;
}
.areaagenda2 {
  height: 600px;
  width: 85%;
  font-size: 1rem;
  border: 2px solid red;
  margin: 20px;
  padding: 5%;
  background-color: aliceblue;
}



/* .agenda {
    grid-area: main;
    display: flex;
    height: 90vh;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    background-color: azure;
  
  
  }

  .agendacontainer{
    display: flex;
  } */